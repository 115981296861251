<template>
<div>
    <div class="shop-index" v-loading="loading">
        <div class="shop-left">
            <div class="shop-wrap">
                <div class="head-wrap">
                <div class="img-wrap">
                    <img
                    class="img-responsive center-block"
                    :src="shopInfo.avatar ? $img(shopInfo.avatar) : $img(defaultShopImage)"
                    @error="shopInfo.avatar = defaultShopImage"
                    :alt="shopInfo.site_name"
                    />
                </div>
                <h5>
                    <span>{{ shopInfo.site_name }}</span>
                    <!-- <el-tag class="tag" size="small" v-if="shopInfo.is_own == 1">认证</el-tag> -->
                </h5>
                </div>
                <div class="info-wrap">
				<dl>
					<!-- <dt class="site-score">店铺评分</dt>
					<dd>
						<el-rate v-model="score" disabled></el-rate>
					</dd> -->
					<dt>所在地区：</dt>
					<dd>
						<span>{{ shopInfo.full_address }}</span>
					</dd>
					<dt>主营业务：</dt>
					<dd>
						<span>{{ shopInfo.category_name }}</span>
					</dd>
					<dt>联&nbsp;&nbsp;系&nbsp;&nbsp;人：</dt>
					<dd>
						<span>{{ shopInfo.name }}</span>
					</dd>
					<dt>会员等级：</dt>
					<dd>
						<span class="vip_shop">{{ shopInfo.group_name }}</span>
					</dd>
				</dl>
                </div>
                <div class="other-info" v-if="false">
					<div class="tell" v-if="shopInfo.telephone">
						<div class="tell-name">电话:</div>
						<div class="tell-detail">{{shopInfo.telephone}}</div>
					</div>
					<div class="item-info" v-if="shopInfo.full_address">
						<div class="item-name">地址:</div>
						<div class="item-detail">{{shopInfo.full_address}}{{shopInfo.address}}</div>
					</div>
                </div>
                <div class="operation">
                <el-button size="medium" @click="getIsFollow" v-if="hasFollow">取消关注</el-button>
                <el-button size="medium" @click="getIsFollow" v-else>关注店铺</el-button>
                </div>
            </div>
			
			<div class="left_contact">
				<div class="intro">如您想了解更多关于“{{ shopInfo.site_name }}”价格、型号、厂家请联系我们，或给我们留言。</div>
				<!-- <div class="tel">400-886-7993</div> -->
				<ul>
					<li v-if="shopInfo.qq"><a :href="'http://wpa.qq.com/msgrd?v=3&uin='+ shopInfo.qq +'&site=qq&menu=yes&from=message&isappinstalled=0'" target="_blank"><img src="../../assets/images/shop_qq.jpg" style="border:0px;margin:0px;"/></a></li>
					<li v-if="shopInfo.wechat">
						<el-popover	ref="popover" placement="top-start" title="微信扫码 添加好友" width="200" trigger="hover">
							<img :src="$img(shopInfo.wechat)" width="200" style="border:0px;margin:0px;"/>
						</el-popover>
						<el-link :underline="false" v-popover:popover><img src="../../assets/images/shop_wx.jpg"/></el-link>
					</li>
					<li><img src="../../assets/images/shop_zx.jpg" style="border:0px;margin:0px;"/></li>
				</ul>
			</div>
			
            <div class="shop-goods-search">
                <el-input
                placeholder="搜索产品"
                v-model="keyword"
                class="input-with-select"
                size="medium"
                maxlength="50"
                clearable
                >
                <el-button slot="append" icon="el-icon-search" @click="goGoodsList"></el-button>
                </el-input>
            </div>
			
            <div class="shop-goods-cate" :class="{border:categoryList.length}">
				<h3>产品分类</h3>
                <div class="item" v-for="item in categoryList" :key="item.category_id">
                <div
                    class="menu-name"
                    @click="$router.push({ path: '/shop_list', query: { site_id: id, shop_category_id: item.category_id } })"
                >{{ item.category_name }}</div>
                <div
                    class="submenu"
                    v-for="cate in item.child_list"
                    :key="cate.category_id"
                    @click="$router.push({ path: '/shop_list', query: { site_id: id, shop_category_id: item.category_id} })"
                >{{ cate.category_name }}</div>
                </div>
            </div>
			
			<div class="goods-recommended">
				<goods-recommend />
			</div>
        </div>
		
        <div class="shop-right">
            <div class="shop-banner">
                <el-carousel height="406px">
                    <!-- <el-carousel-item v-for="item in adList" :key="item.adv_id">
                    <el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" />
                    </el-carousel-item> -->
					<el-carousel-item v-for="item in bannerList">
					<el-image :src="$img(item)" fit="cover" />
					</el-carousel-item>
                </el-carousel>
            </div>
            <div class="content">
				<el-dialog title="企业视频展示" center="true" :visible.sync="dialogVideoVisible">
					<div style="text-align:center;"><video width="100%" controls="true" :src="$img(shopInfo.video_url)" :poster="$img(shopInfo.video_cover)" style="outline: none;"></video></div>
				</el-dialog>
				<div class="about">
					<h5 style="border:0;">企业简介</h5>
					<!-- <table width="100%" height="260">
						<tr>
							<td width="400" style="background-color: #000;">
								<video width="400" height="260" controls="true" :src="$img(shopInfo.video_url)" :poster="$img(shopInfo.video_cover)" style="outline: none;"></video>
							</td>
							<td width="50"></td>
							<td valign="top" style="position:relative;">
								<ul>
									<li>公司主管：数控机床、加工机床</li>
									<li>公司地址：{{shopInfo.full_address}}{{shopInfo.address}}</li>
									<li>联&nbsp;&nbsp;系&nbsp;人：{{shopInfo.name}}</li>
									<li>联系方式：{{shopInfo.mobile}}</li>
								</ul>
								<div style="position:absolute;bottom:0px;left:0px;"><a :href="shopInfo.vr_url" target="_blank"><img src="../../assets/images/shop_vr.jpg" /></a></div>
							</td>
						</tr>
					</table> -->
					<div class="intro">{{shopInfo.seo_description}}</div>
					<div class="video">
						<dl>
							<dt @click="dialogVideoVisible = true">
								<img :src="$img(shopInfo.video_cover)" width="396" height="220" />
								<div class="video_play"></div>
							</dt>
							<dd>企业视频展示</dd>
						</dl>
						<dl>
							<dt>
								<a :href="shopInfo.vr_url" target="_blank">
									<img src="../../assets/images/vr_default.png" width="396" height="220" />
									<div class="vr_play"></div>
								</a>
							</dt>
							<dd>企业VR展示</dd>
						</dl>
					</div>
				</div>
				
				<div class="goods-info" v-if="qualificationList.length">
					<h5>企业照片</h5>
					<div class="item" v-for="(item, index) in qualificationList">
						<el-image class="img-wrap" :src="$img(item)" :preview-src-list="[$img(item)]"></el-image>
					</div>
				</div>
				
				<div class="map">
					<h5>联系我们</h5>
					<h3>{{ shopInfo.site_name }}</h3>
					<table>
						<tr>
							<td align="left" width="50%">联&nbsp;&nbsp;系&nbsp;人：{{shopInfo.name}}</td>
							<td align="left">电子邮箱：{{shopInfo.email}}</td>
						</tr>
						<tr>
							<td align="left" width="50%">联系电话：{{shopInfo.mobile}}　(<a href="javascript:;" @click="getShopContact">查看</a>)</td>
							<td align="left">公司地址：{{shopInfo.full_address}}{{shopInfo.address}}</td>
						</tr>
					</table>
					<iframe :src="'http://www.jhok.net/baidu_map.php?x='+shopInfo.longitude+'&y='+shopInfo.latitude" width="850" height="450" frameborder="0"></iframe>
				</div>
				
            </div>
        </div>
    </div>
    </div>
</div>
</template>

<script>
import { shopInfo, isFollow, unFollow, follow,tree,shopContact } from "@/api/shop/index";
import GoodsRecommend from '@/components/GoodsRecommend';
import { goodsSkuPage } from "@/api/goods/goods";
import { mapGetters } from "vuex";
import { adList } from "@/api/website";
export default {
  name: "shop_index",
  components: {GoodsRecommend},
  data: () => {
    return {
      id: 0,
      currentPage: 1,
      pageSize: 6,
      shopInfo: {}, //店铺信息
      hasFollow: false, //是否关注
      searchContent: "",
	  qualificationList:[],
      categoryList: [],
      goodsList: [],
      total: 0,
      loading: true,
      keyword: "",
      adList: [],
	  bannerList:[],
	  dialogVideoVisible: false,
    };
  },
  computed: {
    ...mapGetters([
      "token",
      "defaultShopImage",
      "addonIsExit",
      "defaultGoodsImage"
    ])
  },
  created() {
    this.id = this.$route.path.replace("/shop-", "");
    this.getAdList();
    this.getShopInfo();
    this.getCategoryList();
    this.getGoodsList();
    this.getfollow();
  },
  watch: {
    $route(curr) {
      this.id = curr.params.pathMatch;
      //this.getAdList();
      this.getShopInfo();
      this.getCategoryList();
      this.getGoodsList();
      this.getfollow();
    }
  },
  methods: {
    getAdList() {
      adList({ keyword: "NS_PC_SHOP_INDEX" })
        .then(res => {
          this.adList = res.data.adv_list;
          for (let i = 0; i < this.adList.length; i++) {
            if (this.adList[i].adv_url)
              this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url);
          }
        })
        .catch(err => err);
    },
    //获取店铺信息.
    getShopInfo() {
      shopInfo({ site_id: this.id })
        .then(res => {
          if (res.code == 0) {
            this.shopInfo = res.data;
			if(this.shopInfo.banner_image!=null && this.shopInfo.banner_image.length>10){
				this.bannerList = this.shopInfo.banner_image.split(',');
			}
			if(this.shopInfo.shop_qualification!=null && this.shopInfo.shop_qualification.length>10){
				this.qualificationList=this.shopInfo.shop_qualification.split(',');
			}
          }
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message);
        });
    },
    //获取店铺商品分类列表
    getCategoryList() {
      tree({ site_id: this.id })
        .then(res => {
          this.categoryList = res.data;
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message);
        });
    },
    //获取店铺商品列表
    getGoodsList() {
      const params = {
        page: this.currentPage,
        page_size: this.pageSize,
        site_id: this.id,
        keyword: this.keyword,
        sort: "desc"
      };
      goodsSkuPage(params || {})
        .then(res => {
          if (res.code == 0 && res.data) {
            let data = res.data;
            this.goodsList = data.list;
            this.total = res.data.count;
            this.loading = false;
          }
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message);
        });
    },
    //获取店铺关注信息
    getfollow() {
      isFollow({
        site_id: this.id
      })
        .then(res => {
          this.hasFollow = res.data;
        })
        .catch(err => {
        });
    },
	//获取店铺联系方式
	getShopContact() {
		shopContact({
			site_id: this.id
		}).then(res => {
			if (res.code == 0) {
				//this.$alert('这是一段内容');
				//this.hasFollow = res.data
				this.shopInfo.mobile = res.data.mobile;
				this.shopInfo.telephone = res.data.telephone;
			}
		}).catch(err => {
			if (err.message == 'token不存在') {
				this.$router.pushToTab('/login')
			} else {
				this.$alert(err.message);
			}
		})
	},
    //关注与取消
    getIsFollow() {
      if (this.hasFollow) {
        unFollow({ site_id: this.id }).then(res => {
          if (res.code == 0 && res.data) {
            this.hasFollow = !this.hasFollow;
            this.$message({
              message: "取消关注成功",
              type: "success"
            });
          }
        }).catch(err => {
			console.log(this.shopInfo,'this.shopInfo')
			if (err.message == 'token不存在') {
				this.$router.push({path: '/login', query: {redirect: "/shop-"+this.shopInfo.site_id}});
			} else {
				this.$message.error(err.message);
			}
		});
      } else {
        follow({ site_id: this.id }).then(res => {
          if (res.code == 0) {
            this.hasFollow = !this.hasFollow;
            this.$message({
              message: "关注成功",
              type: "success"
            });
          }
        }).catch(err => {
			if (err.message == 'token不存在') {
				this.$router.push({path: '/login', query: {redirect: "/shop-"+this.shopInfo.site_id}});
			} else {
				this.$message.error(err.message);
			}
		});
}
    },
    goGoodsList() {
      this.$router.push({
        path: "/shop_list",
        query: { site_id: this.id, keyword: this.keyword }
      });
    },
    handlePageSizeChange(num) {
      this.pageSize = num;
      this.getGoodsList();
    },
    handleCurrentPageChange(page) {
      this.currentPage = page;
      this.getGoodsList();
    },
    imageError(index) {
      this.goodsList[index].sku_image = this.defaultGoodsImage;
    }
  }
};
</script>
<style lang="scss" scoped>
.shop-index {
  padding-top: 10px;
  display: flex;
  background-color: #ffffff;
  .shop-left {
    width: 234px;
    .shop-wrap {
      width: 192px;
      //float: right;
      border: 1px solid #e9e9e9;
	  border-bottom:0;
      padding: 0 21px;
      .head-wrap {
        text-align: center;
        padding: 10px 0;
        border-bottom: 1px solid #eeeeee;
        .img-wrap {
          width: 60px;
          height: 60px;
          line-height: 60px;
          display: inline-block;
        }
        .tag {
          margin-left: 5px;
        }
      }
      .info-wrap {
      	padding: 20px 0;
      	//border-bottom: 1px solid #f1f1f1;
      	dl {
      		font-size: $ns-font-size-sm;
      		dt {
      			color: #838383;
      			// width: 65px;
      			line-height: 25px;
      			font-weight: normal;
      			float: left;
      		}
      		dt.site-score {
      			padding-right: 5px;
      		}
      		dd {
      			height: 25px;
      			line-height: 25px;
      			// margin-left: 50px;
      			color: #838383;
      			span {
      				color: #383838;
      				font-weight: 600;
      			}
      
      			.el-rate {
      				padding-top: 2px;
      			}
      		}
      	}
      }
	  .vip_shop{padding-left:30px;background:url(../../assets/images/shop_vip.png) no-repeat left center;color:#FE8121!important;}
      .other-info {
        padding: 10px;
        color: #838383;
        .item-info {
          display: flex;
          .item-name {
            width: 75px;
          }
        }
        .tell {
          display: flex;
          .tell-name {
            width: 35px;
          }
        }
      }
      .operation {
        border-top: 1px solid #eeeeee;
        padding: 20px 0;
        text-align: center;
		button{width:160px;color:#FE8121;border-color:#FE8121;}
      }
	  
    }
	.left_contact{width:234px;border: 1px solid #E5E5E5;border-top:0;padding-bottom:10px;}
	.left_contact div.intro{width:204px;margin:auto;font-size:14px;padding-bottom:20px;}
	.left_contact div.tel{hieght:24px;width:148px;padding-left:32px;background:url(../../assets/images/shop_tel.jpg) no-repeat left center;margin:auto;margin-top:26px;margin-bottom:26px;font-weight:bold;font-size:22px;line-height:22px;}
	.left_contact li{margin-bottom:16px;text-align:center;}
    .shop-goods-search {
      .el-input {
        margin: 20px 0;
      }
    }
    .border{
      border: 1px solid #e9e9e9;
    }
    .shop-goods-cate {
      width: 100%;
	  margin-bottom:20px;
	  h3{background-color:#F5F7FA;text-align: center;font-size:14px;line-height:34px;border-bottom: 1px solid #e9e9e9;}
      .menu-name {
        padding-left: 16px;
        //background: #f8f8f8;
        font-size: $ns-font-size-base;
        height: 40px;
        cursor: pointer;
        color: #666666;
        display: flex;
        align-items: center;
      }
      .submenu {
        font-size: $ns-font-size-base;
        line-height: 2;
        border-top: 1px solid #f1f1f1;
        padding-left: 28px;
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 40px;
        background: #ffffff;
        color: #666666;
      }
    }
  }
  .shop-right {
    margin-left: 26px;
    width: 100%;
	padding-bottom:30px;
    .shop-banner {
      width: 100%;
    }
	.content{border: 1px solid #eeeeee;margin-top:30px;}
	h5{width:850px;margin:auto;text-align: left;font-size:18px;line-height:100px;font-weight:normal;border-top:2px solid #F5F7FA;padding-top:2px;}
    .about {
		width:850px;
		margin:auto;
		//margin-top: 60px;
		margin-bottom:50px;
		line-height:150%;
		.about{text-align: left;font-size: 14px;color:grey;}
		dt{
			position:relative;
			div{width:396px;height:220px;background:url(../../assets/images/video_play.png) no-repeat center center;position:absolute;z-index:100;top:0px;left:0px;cursor:pointer;}
		}
		dd{font-size:16px;color:white;line-height:66px;text-align:center;background-color:#FE8D37;font-family: "microsoft yahei";margin-top:1px;}
		.video{margin-top:50px;display:flex;justify-content: space-between;}
		.vr_play{background:url(../../assets/images/vr_play.png) no-repeat center center;}
    }
    .goods-info {
	  width:850px;
	  margin:auto;
	  padding-bottom:20px;
      margin-top: 5px;
      display: flex;
	  justify-content: space-between;
      flex-wrap: wrap;
      .item {
        width: 260px;
        margin-bottom:30px;
        border: 1px solid #eeeeee;
        position: relative;
        &:hover {
          border: 1px solid $base-color;
        }
        .img-wrap {
          width: 260px;
          height: 190px;
          cursor: pointer;
        }
      }
    }
	.map{
		width:850px;margin:auto;
		padding-bottom:30px;
		font-size:14px;
		h3{margin-bottom:10px;}
		iframe{margin-top:30px;}
	}
  }
}
</style>
